import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import { H1 } from "../components/Typography";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import Underline from "../components/Underline";
import useSiteMetadata from "../components/SiteMetadata";

const CustomPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { siteUrl } = useSiteMetadata();

  const content = post.frontmatter;
  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={content.seo.title}
        description={content.seo.description}
        openGraph={{
          url: `${siteUrl}${post.fields.slug}`,
          title: content.seo.title,
          description: content.seo.description,
          images: [{ url: `${siteUrl}${content.seo.image.publicURL}` }],
          site_name: "MycoForest"
        }}
      />
      <section>
        <div className="container">
          <H1 alignCenter={false}>{content.title}</H1>
          <Underline />
          <HTMLContent className="markdown" content={post.html} />
        </div>
        <style jsx>{`
          section {
            padding: 32px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          @media (max-width: 480px) {
            section {
              padding: 32px 20px;
            }
          }
        `}</style>
      </section>
    </Layout>
  );
};

export default CustomPage;

export const pageQuery = graphql`
  query CustomPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
          image {
            publicURL
          }
        }
        title
      }
    }
  }
`;
